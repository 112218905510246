import { useEffect } from "react";
import PortalOneWebConfig from "../PortalOneWebConfig"
import { loginCall } from "./AuthApiHelper";

export default function GitHubAuth() {

  const loginWithGitHub = () => {
    const clientID = PortalOneWebConfig("githubAuthClientId");
    const redirectURI = PortalOneWebConfig("baseUrl") + '/auth/github/callback';
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}`;
  };

  useEffect(() => {//vlt. besser jedes mal den code holen und mit frischen code den access token abholen
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code && localStorage.getItem("accessToken") === null) {
      getToken(code);
    }
  }, []);

  const getToken = async(code: string) => {
    await loginCall("github", "", {code});
    window.location.href = PortalOneWebConfig("userEntryPoint");
  }

  return (
    <div>
      <button onClick={loginWithGitHub} className="social-button github-button" style={{ width: "100%"}}>
        Weiter mit GitHub
      </button>
    </div>
  );
}