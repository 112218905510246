import { useState } from "react"
import { removeBox, setBoxTitle } from "./BoardStoreSlice"
import { useDispatch, useSelector } from "react-redux";
import BoxListElement from "./BoxListElement";
import BoxFormElement from "./BoxFormElement";
import './Box.css';



function Box(props: any) {

  const boxId: number = props.boxid; //index im store slice array
  const year: number = props.year; //the year selected in the board @TODO use :D
  const dispatch = useDispatch();
  
  const boardState = useSelector((state: {boardStoreSlice: any}) => state.boardStoreSlice);
  const boxTitle = (): string => {
    //@ts-ignore
    return boardState.boxes[boxId].title;
  }

  const [Title, setTitle]:any = useState(boxTitle());
  const [titleEditMode, setTitleEditMode]:any = useState(false);
  const [displayAddItem, setDisplayAddItem]:any = useState(false);
  const [minimized, setMinimized]:any = useState(false);


  const boxElements = () => {
    //@ts-ignore
    return [...boardState.boxes[boxId].elements];
  }



  const flipTitleEditMode = () => {
    if(titleEditMode) {
      //@ts-ignore
      dispatch(setBoxTitle({boxId, title: Title}));
    }
    setTitleEditMode(!titleEditMode);
  }



  const deleteBox = () => {
    //@ts-ignore
    dispatch(removeBox(boxId));
  }


  //move to BoardStoreSlice to reuse for boardTotals
  const boxTotal = (frequency: string = ''): number => {
    //@ts-ignore
    switch(frequency) {
      case 'm':
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'm') ? (element.type === 'i') ? element.total : element.total * -1 : 0), 0);
      case 'y':
        return boxTotal('m')*12 + boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'y') ?  (element.type === 'i') ? element.total : element.total * -1 : 0), 0);
      case 'o':
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'o') ?  (element.type === 'i') ? element.total : element.total * -1 : 0), 0);
      default:
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.type === 'i') ? element.total : element.total * -1), 0);
    }
  }



  return (
      <div className="card">

        <div className="card-header">
          <div onClick={() => setMinimized(!minimized)}>
            <span className="" style={{paddingLeft: '0'}}>
              <button className={((titleEditMode ? "finansp-btn-light" : "finansp-btn-primary") + " header-btn")} onClick={() => flipTitleEditMode()}>
                <i className={titleEditMode ? "fa fa-arrow-left" : "fa fa-ellipsis-vertical"}></i>
              </button>
              <h2 style={{marginLeft: '45px', marginBottom: '20px'}}>{ (titleEditMode ? "Set Title" : boxTitle())}</h2>
          
          {titleEditMode === true && (
            <>
              <div className="input-group" style={{width: '100%', textAlign: 'center'}}>
                <div style={{display: 'flex'}}>
                  <div>
                    <input value={Title} placeholder="Give this Box a Title" style={{width: 'calc(100% - 41px)'}} onChange={(e: any) => setTitle(e.target.value)} />
                  </div>
                  <div>
                    <button className="finansp-btn-dark" style={{width: '41px', height: '41px'}} onClick={() => flipTitleEditMode()}><i className={titleEditMode ? "fa fa-save" : "fa fa-ellipsis-vertical"}></i></button>
                  </div>
                </div>
              </div>
              <div className="box-controls-right">
                <button className="finansp-btn-danger" onClick={() => deleteBox()}><i className="fa fa-trash"></i></button>
              </div>
            </>
          )}
            </span>
          </div>

        </div>

        <div className="card-body" style={{backgroundColor: 'white', padding: 0}}>

          <div>
              <table style={{width: '100%', textAlign: 'center'}}>
                <tbody>
                  <tr>
                    <td><p className="year-font-lg" style={{margin: '0'}}>Monthly</p></td>
                    <td><p className="year-font-lg" style={{margin: '0'}}>One Time</p></td>
                    <td><p className="year-font-lg" style={{margin: '0'}}>Yearly</p></td>
                  </tr>
                  <tr>
                    <td><p style={{fontWeight: 'bold', margin: '0'}}>{boxTotal('m').toFixed(2)} €</p></td>
                    <td><p style={{fontWeight: 'bold', margin: '0'}}>{boxTotal('o').toFixed(2)} €</p></td>
                    <td><p style={{fontWeight: 'bold', margin: '0'}}>{boxTotal('y').toFixed(2)} €</p></td>
                  </tr>
                </tbody>
              </table>
          </div>
  
        { minimized === false &&
            <div className="booking-list" style={{maxHeight: '40vh', overflowY: 'scroll', overflowX: 'hidden'}}>
              {boxElements().map((element: any, index: number) => (
                <div key={index} className="booking-item">
                  <BoxListElement boxid={boxId} elementIndex={index} />
                </div>
                ))
              }
            </div>
        }

          </div>

        { minimized === false &&
        <div className="card-footer">
          { !displayAddItem &&
            <div style={{width: '100%', textAlign: 'center'}}>
              <button className="finansp-btn-secondary" style={{opacity: 0.5, fontSize: '1.2em', paddingTop: '0', paddingBottom: '0', width: '85%'}} onClick={() => setDisplayAddItem(true)}><b>+</b></button>
            </div>
          }
          <div>
            { displayAddItem &&
              <div>
                <BoxFormElement mode="add" elementSavedCallback={() => setDisplayAddItem(false)} onCancel={() => setDisplayAddItem(false)} boxId={boxId} />
                <hr />
              </div>
            }
          </div>


        </div>
        }

    </div>
  )
}

export default Box