import { useState } from "react";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";


function Navigation(props: {title: string, showBackButton: boolean, backButtonRoute?: string, showIcon: boolean, iconCallback: () => void}) {
  const navigate = useNavigate();
  const backButtonRoute = props.backButtonRoute ?? "/";

  const [menuState, setMenuState] = useState(false);
  const toggleMenu = () => setMenuState(!menuState);


  return (

<div className="navigation-bar">
  {menuState && 
    <div className="menu-overlay" onClick={() => toggleMenu()}>
      <div className="menu-content" onClick={(e) => {e.stopPropagation()}}>
        <UserProfile />
      </div>
    </div>
  }

  {props.showBackButton && 
    <div className="nav-button-container">
      <button className="finansp-btn-secondary" onClick={() => navigate(backButtonRoute)}>Go Back</button>
    </div>
  }

  <div className="nav-title-container">
    <h1>{props.title}</h1>
  </div>

  {props.showIcon && 
    <div className="user-profile-btn-container">
      <button className="finansp-btn-userprofile" onClick={() => toggleMenu()}>CK</button>
    </div>
  }
</div>
  );
}

export default Navigation;