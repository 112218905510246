import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBoxElement, updateBoxElement } from "./BoardStoreSlice";
import './BoxFormElement.css';

function BoxFormElement(props: {mode: 'add' | 'edit', elementSavedCallback: any, onCancel: any, boxId: number, elementIndex?: number}) {

  type BoxElement = {
    title: string;
    total: number;
    date: string;
    frequency: 'm'| 'y' | 'o';
    type: 'i' | 'e';
    payed: boolean;
  }
  
  const mode = props.mode;
  const dispatch = useDispatch();
  const boardState = useSelector((state: {boardStoreSlice: any}) => state.boardStoreSlice);




  const initElement = () => {
    return (mode === 'edit' && props.elementIndex !== undefined) ?
      (boardState.boxes[props.boxId].elements[props.elementIndex]) :
      {
        title: "",
        total: 0,
        date: new Date().toISOString().split('T')[0],
        frequency: 'o',
        type: 'e',
        payed: false,
      }
  }

  const [newElement, setNewElement]: any = useState<BoxElement>(() => initElement());


  const createElement = (event: any) => {
    event.preventDefault();
    setNewElement(initElement());
    dispatch(addBoxElement({boxId: props.boxId, element:newElement}));
    props.elementSavedCallback();
  }



  const updateElement = (event: any) => {
    event.preventDefault();
    dispatch(updateBoxElement({boxId: props.boxId, elementIndex: props.elementIndex, newElement: newElement}));
    props.elementSavedCallback();
  }



  return (
    <div>
      <form onSubmit={mode === 'add' ? createElement : updateElement}>
  
        <div className="input-group" style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

         <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{}}>
            <label htmlFor="inputNewElementTitle">Title</label>
            <input id="inputNewElementTitle" type="text" value={newElement.title} onChange={(e: any) => setNewElement({...newElement, title: e.target.value})} placeholder="Titel" className="form-control"/>
          </div>
  
          <div style={{width: '4em'}}>
            <label htmlFor="inputNewElementTotal">Total</label>
            <input id="inputNewElementTotal" type="number" min={0} value={newElement.total} onChange={(e: any) => setNewElement({...newElement, total: parseFloat(e.target.value)})} placeholder="Wert" className="form-control" />
          </div>

          <div style={{}}>
            <label htmlFor="expenseOrincome" className="text-white">_</label>
            <div id="expenseOrincome" style={{display: 'flex'}} aria-label="Basic radio toggle button group">
            <input type="button" value="+" className={(newElement.type !== 'i') ? "finansp-btn-success" : "finansp-btn-primary"} onClick={() => setNewElement({...newElement, type: 'i'})} /> 
            <input type="button" value="-" className={(newElement.type !== 'e') ? "finansp-btn-danger" : "finansp-btn-primary"} onClick={() => setNewElement({...newElement, type: 'e'})} />
            </div>
          </div>
  
          </div>
         <div style={{width: '100%', display: 'flex'}}>
          <div className="">
            <label htmlFor="inputNewElementTitle">Start Date</label>
            <input id="inputNewElementTitle" type="date" value={newElement.date} onChange={(e: any) => setNewElement({...newElement, date: e.target.value})} placeholder="Titel" className="form-control"/>
          </div>
  
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <label htmlFor="frequencyButtons" className="">Frequency</label>
            <div id="frequencyButtons" style={{display: 'flex', marginLeft: '10px'}} aria-label="Basic radio toggle button group">
              <input type="button" value="o" className={(newElement.frequency !== 'o') ? "finansp-btn-light" : "finansp-btn-info"} name="frequencyradio" id="frequencyradio1" onClick={() => setNewElement({...newElement, frequency: 'o'})} />
              <input type="button" value="m" className={(newElement.frequency !== 'm') ? "finansp-btn-light" : "finansp-btn-info"} name="frequencyradio" id="frequencyradio2" onClick={() => setNewElement({...newElement, frequency: 'm'})} />
              <input type="button" value="y" className={(newElement.frequency !== 'y') ? "finansp-btn-light" : "finansp-btn-info"} name="frequencyradio" id="frequencyradio3" onClick={() => setNewElement({...newElement, frequency: 'y'})} />
  
            </div>
          </div>
          </div>
  
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <div className="col-6">
              <button className="finansp-btn-secondary" onClick={() => props.onCancel()}>cancel</button>
            </div>
            <div className="col-6">
              <input type="submit" id="boxAddValueButton" className="finansp-btn-primary" value="save" />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BoxFormElement;