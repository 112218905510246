import { useEffect, useState } from "react";
import "./ListOfBoards.css";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../identityProvider/useAuthentication";
import axios from "axios";
import PortalOneWebConfig from "../../PortalOneWebConfig";
import Navigation from "../Navigation";

export default function ListOfBoards() {

  useAuthentication();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");

  const [boardsList, setBoardsList] = useState([]);

  useEffect(() => {
    getListOfBoards();
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const createBoard = () => {
    if (title.length === 0) {
      return;
    }
    axios.get(PortalOneWebConfig("portalOneApiUrl"), { 
      headers: { Authentication: localStorage.getItem("access_token") }, 
      params: { action: "createBoard", title: title, data: '{"title":"untitled","boxes":[]}' } })
      .then(res => {
        getListOfBoards();
        console.log(res);
      }).catch(err => {
        console.log(err);
      });
  };

  const getListOfBoards = () => {
    axios.get(PortalOneWebConfig("portalOneApiUrl"), { 
      headers: { Authentication: localStorage.getItem("access_token") }, 
      params: { action: "get_boards_list" } })
      .then(res => {
        setBoardsList(res.data);
      }).catch(err => {
        console.log(err);
      });
  };

  const openBoard = (boardid: number) => {
    navigate("/viewBoard?boardId=" + boardid);
  };

  return(
    <div>
  <Navigation title="Your Boards" showBackButton={false} showIcon={true} iconCallback={() => navigate("/user-profile")} />
  
    <div className="board-list-container">
    {boardsList.map((board, index) => (
      <div key={index} className="board-item">
        <span className="board-item-text" 
          /*@ts-ignore*/
          onClick={() => openBoard(board.id)}>
          {/*@ts-ignore*/
          board.name}
        </span>
        <hr />
      </div>
    ))}
  </div>
<div className="create-board-container">
    <input 
      type="text" 
      placeholder="New Board" 
      value={title} 
      onChange={(e) => setTitle(e.target.value)} 
      className="board-name-input" 
    />
    <button 
      className="finansp-btn-primary create-board-btn" 
      onClick={() => createBoard()}>
        Create
      </button>
    </div>



   </div>
  );
}