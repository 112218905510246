import { useEffect, useState } from "react";
import "./LoginSignup.css";
import GitHubAuth from "./identityProvider/GitHubAuth";
import GoogleAuth from "./identityProvider/GoogleAuth";
import { loginCall, signupCall, activateAccountCall } from './identityProvider/AuthApiHelper';
import PortalOneWebConfig from "./PortalOneWebConfig";

function LoginSignup() {

 
  type FormState = "login" | "signup";
  const [formState, setFormState] = useState<FormState>("login");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notification, setNotification] = useState("");

  useEffect(() => {//vlt. besser jedes mal den code holen und mit frischen code den access token abholen
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('activate');
    if (code) {
      activateAccount(code);
    }
  }, []);


  const login = async () => {
    if(email === "" || password === "") return;
    await loginCall("portalOne", "", {email, password});
    window.location.href = PortalOneWebConfig("userEntryPoint");
  }

  const signup = async () => {
    if(email === "" || username === "" || password === "" || confirmPassword === "" || password !== confirmPassword) return;
    await signupCall(email, username, password);
    setNotification("Please check your email for a confirmation link.");
    //window.location.href = PortalOneWebConfig("userEntryPoint");
    console.log("signup", email, username, password, confirmPassword);
  }

  const activateAccount = async (code: string) => {
    await activateAccountCall(code);
    window.location.href = PortalOneWebConfig("userEntryPoint");
  }

  const toggleFormState = () => {
    setFormState(formState === "login" ? "signup" : "login");
  }

  return (
<div>
    {/*<div className="card">
      <div className="card-header">
        <h2>Login</h2>
      </div>
      <div className="card-body">

          <div>
            <p>{notification}</p>
          </div>


          <div className="form-group">
            <input className="form-control" type="email" placeholder="Email" onChange={(e: any) => setEmail(e.target.value)} />
            {formState === "signup" && <input className="form-control" type="text" placeholder="Username" onChange={(e: any) => setUsername(e.target.value)} />} 
            <input className="form-control" type="password" placeholder="Password" onChange={(e: any) => setPassword(e.target.value)} />
            {formState === "signup" && <input className="form-control" type="password" placeholder="Confirm Password" onChange={(e: any) => setConfirmPassword(e.target.value)} />} 
          </div>
          <button className="finansp-btn-primary w-100 mt-2" onClick={() => (formState === "login" ? login() : signup())}>{formState === "login" ? "Login" : "Signup"}</button>
          <button className="finansp-btn-info w-100 mt-2" onClick={() => toggleFormState()}>{formState === "login" ? "Signup" : "zum Login"}</button>
          <br />
      </div>
      <div className="mt-2">
        <GoogleAuth />
      </div>
      <div className="mt-2">
        <GitHubAuth />
      </div>
*/}

    <div className="login-container">
        <div className="login-card">
            <h2>Anmelden</h2>
            <p>Bitte melden Sie sich an, um fortzufahren.</p>
             <div>
              <p>{notification}</p>
            </div>

            <form action="#" method="post">
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="Email" placeholder="your@email.com" required onChange={(e: any) => setEmail(e.target.value)} />
                    {formState === "signup" &&
                      <div>
                        <label htmlFor="username">Username</label>
                        <input id="username" type="text" placeholder="Username" onChange={(e: any) => setUsername(e.target.value)} />
                      </div>
                    } 
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" required onChange={(e: any) => setPassword(e.target.value)}/>
                    {formState === "signup" &&
                      <div>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input id="confirmPassword" className="form-control" type="password" placeholder="Confirm Password" onChange={(e: any) => setConfirmPassword(e.target.value)} />
                      </div>
                    } 
                </div>
                <button type="button" className="finansp-btn-primary login-button" onClick={() => (formState === "login" ? login() : signup())}>{formState === "login" ? "Login" : "Sign Up"}</button>
            </form>


            <div className="divider">
                <span>oder</span>
            </div>
            <div className="social-login">
              <GoogleAuth />
              <GitHubAuth />
              <i className="fa-solid fa-user"></i>

            </div>



            <div className="login-footer">
                <p onClick={() => toggleFormState()}>{formState === "login" ? "Don't have an account yet? Click here to sign up" : "Back to Login"}</p>
            </div>
        </div>
    </div>



    </div>
  )
}

export default LoginSignup;