import { useState } from "react";
import './BoxListElement.css';
import { useDispatch, useSelector } from "react-redux";
import { removeBoxElement } from "./BoardStoreSlice";
import BoxFormElement from "./BoxFormElement";


function BoxListElement(props: {boxid: number, elementIndex: number}) {

  const dispatch = useDispatch();
  const boardState = useSelector((state: {boardStoreSlice: any}) => state.boardStoreSlice);
  const listElement = (): any => {
    //@ts-ignore
    return boardState.boxes[props.boxid].elements[props.elementIndex];
  }

  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);



  const deleteElement = () => {
    //@ts-ignore
    dispatch(removeBoxElement({elementIndex: props.elementIndex, boxId: props.boxid}));
  }

  const editElement = () => {
    setDisplayEdit(false);
    setDisplayForm(true);
  }



  return (
    <div className="booking-details">
      
          <div className="booking-item">
            <div className="booking-details" onClick={() => displayForm ? setDisplayForm(false) : editElement()}>
              <span>{listElement().title}</span>
              <div>
                <span className={`booking-amount ${listElement().type === 'i' ? 'booking-income' : 'booking-expense'}`}>
                  {listElement().type === 'i' ? `+€${listElement().total.toFixed(2)}` : `€${listElement().total.toFixed(2)}`}
                <span className="booking-frequency">
                  {listElement().frequency === 'm' ? 'monthly' : listElement().frequency === 'y' ? 'yearly' : 'one time'}
                </span>
              </span>
              </div>
            </div>
          </div>
          { displayForm &&
            <div className="">
              <button className="finansp-btn-danger small-btn" onClick={deleteElement}>
                <i className="fa-solid fa-trash"></i>
              </button>
            <BoxFormElement mode="edit" elementSavedCallback={() => setDisplayForm(false)} onCancel={() => setDisplayForm(false)} boxId={props.boxid} elementIndex={props.elementIndex} />
            <hr />
            </div>
          }
    </div>
  );
}

export default BoxListElement;
