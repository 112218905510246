import { GoogleLogin } from "@react-oauth/google";
import { loginCall } from "./AuthApiHelper";
import PortalOneWebConfig from "../PortalOneWebConfig";

export default function GoogleAuth() {

  type GoogleResponse = {
    clientId: string;
    creadential: string;
    select_by: string;
  };

  const responseMessage = async(response: GoogleResponse) => {
    //@ts-ignore
    await loginCall("google", response.clientId, response.credential);
    window.location.href = PortalOneWebConfig("userEntryPoint");
  };
  const errorMessage = (error: any) => {
      console.log(error);
  };

  return (
    <div>
      {//@ts-ignore
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
      }
    </div>
  );
}
