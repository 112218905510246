import axios from "axios";
import PortalOneWebConfig from "../PortalOneWebConfig";

export async function loginCall(provider: string, thirdPartyId: string, data: any = null) {
  //console.log("loginCall", provider, thirdPartyId, data);
  try {
    const response = await axios.get(
      PortalOneWebConfig('portalOneApiUrl'),
      {params: {
        provider, 
        thirdPartyId, 
        data,
        action: "login"
      }}
    );
    //@ts-ignore
    if(response) localStorage.setItem("access_token", response.headers.get("Authentication")); //@TODO implement correct
  } catch (error) {
    console.error(error);
  }
}

export async function signupCall(email: string, username: string, password: string) {
  try {
    const response = await axios.get(
      PortalOneWebConfig('portalOneApiUrl'),
      {params: {
        email, 
        username, 
        password,
        action: "signup"
      }}
    );
    console.log(response);
    //@ts-ignore
    //if(response) //localStorage.setItem("access_token", response.headers.get("Authentication")); //@TODO implement correct
  } catch (error) {
    console.error(error);
  }
}

export async function activateAccountCall(code: string) {
  try {
    const response = await axios.get(
      PortalOneWebConfig('portalOneApiUrl'),
      {params: {
        code,
        action: "activate"
      }}
    );
    //@ts-ignore
    if(response) localStorage.setItem("access_token", response.headers.get("Authentication")); //@TODO implement correct
  } catch (error) {
    console.error(error);
  }
}