import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import UserProfile from './pages/UserProfile';
import ListOfBoards from './pages/boardsList/ListOfBoards';
import ProtectedRoutes from './pages/ProtectedRoutes';
import Board from './pages/board/Board';

function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="*" element={<Home />}></Route>
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/user-profile" element={<UserProfile />}></Route>
            <Route path="/userProfile" element={<UserProfile />}></Route>
            <Route path="/listOfBoards" element={<ListOfBoards />}></Route>
            <Route path="/viewBoard" element={<Board />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;