import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../identityProvider/useAuthentication";

export default function UserProfile() {

  useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };
  
  return(
    <div className="p-2" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', color: 'lightgray'}}>
      <h1>User Profile</h1>
      <button className="finansp-btn-secondary" onClick={() => navigate("/listOfBoards")}>List of Boards</button>
      <hr />
      <button className="finansp-btn-primary" onClick={() => logout()}>Logout</button>
{/*
      <hr />

      <div className="">
          <button className="finansp-btn-primary" >primary</button>
          <button className="finansp-btn-secondary" >secondary</button>
          <button className="finansp-btn-danger" >danger</button>
          <button className="finansp-btn-info" >info</button>
          <button className="finansp-btn-warning" >warning</button>
          <button className="finansp-btn-userprofile" >userprofile</button>

<button className="add-item-button">test</button>
        <button className="small-btn">test</button>
        <button className="finansp-btn-primary">test</button>
        <button className="finansp-btn-secondary">test</button>
        <button className="finansp-btn-dark">test</button>
        <button className="finansp-btn-light">test</button>
        <button className="finansp-btn-warning">test</button>
        <button className="finansp-btn-info">test</button>
        <button className="finansp-btn-danger">test</button>
        <button className="finansp-btn-success">test</button>
        <button className="finansp-btn-userprofile">test</button>


        </div>

 */}
    </div>
  );
}