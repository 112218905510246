import { createSlice } from '@reduxjs/toolkit'

export const boardStoreSlice = createSlice({
  name: 'board',
  initialState: {
    title: "dummytitle",
    boxes:
      [{id: 0, title: "dummy", elements: [
        {title: "dummyElement", total: 22},
      ]}] //elements of box 0
    ,
  },
  reducers: {

    setBoard: (state, value) => {
      return value.payload;
    },

    setTitle: (state, value) => {
      return { ...state, title: value.payload };
    },

    addBox: (state, value) => {
      const title = value.payload.title ?? "New Box";
      return { ...state, boxes: [...state.boxes, {id: state.boxes.length, title: title, elements: []}] };
    },

    removeBox: (state, value) => {
      const boxId = value.payload;
      const updatedBoxes = state.boxes.filter((_, index) => index !== boxId);
      return { ...state, boxes: updatedBoxes };
    },

    setBoxTitle: (state, value) => {
      const boxId = value.payload.boxId;
      const newTitle = value.payload.title;

      const newBoxObject = {
        ...state.boxes[boxId],
        title: newTitle,
      };

      return {
        ...state,
        boxes: [...state.boxes.slice(0, boxId), newBoxObject, ...state.boxes.slice(boxId + 1)]
      };
    },

    addBoxElement: (state, value) => {
      const boxId = value.payload.boxId;
      const element = value.payload.element;

      console.log("addBoxElement", element);

      const newBoxObject = {
        ...state.boxes[boxId],
        elements: [...state.boxes[boxId].elements, element],
      };

      console.log("newBoxObject", newBoxObject);

      return {
        ...state,
        boxes: [...state.boxes.slice(0, boxId), newBoxObject, ...state.boxes.slice(boxId + 1)]
      };

    },

    removeBoxElement: (state, value) => {
      const boxId = value.payload.boxId;
      const elementIndex = value.payload.elementIndex;


      const newBoxElements = state.boxes[boxId].elements.filter((_, index) => index !== elementIndex);

      const newBoxObject = {
        ...state.boxes[boxId],
        elements: newBoxElements,
      };

      return {
        ...state,
        boxes: [...state.boxes.slice(0, boxId), newBoxObject, ...state.boxes.slice(boxId + 1)]
      };
    },

    updateBoxElement: (state, value) => {
      const boxId = value.payload.boxId;
      const elementIndex = value.payload.elementIndex;
      const newElement = value.payload.newElement;

      const newBoxObject = {
        ...state.boxes[boxId],
        elements: [...state.boxes[boxId].elements.slice(0, elementIndex), newElement, ...state.boxes[boxId].elements.slice(elementIndex + 1)]
      };

      return {
        ...state,
        boxes: [...state.boxes.slice(0, boxId), newBoxObject, ...state.boxes.slice(boxId + 1)]
      };
    }

    
  },
})

export const { setBoard, setTitle, addBox, removeBox, setBoxTitle, addBoxElement, removeBoxElement, updateBoxElement } = boardStoreSlice.actions

export default boardStoreSlice.reducer