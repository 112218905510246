import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginSignup from "../LoginSignup";
import PortalOneWebConfig from "../PortalOneWebConfig";


export default function Home() {

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      console.log("should redirect to user profile");
      navigate(PortalOneWebConfig("userEntryPoint"));
    }
  }, [navigate]);

  return(
  <div>
    <h1>Finansphere</h1>
    <div className="row">
      <div className="col-lg-3 col-md-6 col-sm-12">
        <LoginSignup />
      </div>
    </div>
    <h2 className="text-center mt-5">Keep It Simple!</h2>
  </div>
);
}