
const config = {
  //"baseUrl": "http://localhost:3000",
  //"portalOneApiUrl": "http://87.106.119.214/finansphere-server/index.php",

  "userEntryPoint": "/listOfBoards",

  "googleAuthClientId": "761627020320-qv9746cjrnpabosnendqp72tao929v10.apps.googleusercontent.com",
  //"githubAuthClientId": "Ov23liRAoi9GuvGabGGc", //localhost
 
  
"githubAuthClientId": "Ov23liNw2fqOwI1RFtVf", //dev.journey4y.de
  "baseUrl": "https://dev.journey4y.de",
  "portalOneApiUrl": "https://dev.journey4y.de/server/index.php",
  
};

export default function getConfigValue(key: string) {
  //@ts-ignore
  return config[key];
}