import axios from "axios";
import { useEffect, useState } from "react";
import PortalOneWebConfig from "../PortalOneWebConfig";


//call this hook to redirect to login page if not authenticated
export default function useAuthentication() {

  const [user, setUser] = useState("pending");

  useEffect(() => {

    //maybe store some values and compare them to see if the token is still valid

    try {
    //@ts-ignore
    if(localStorage.getItem("access_token") !== undefined) {

      const uidActiveToken = parseJwt(localStorage.getItem("access_token") ?? "")?.uid;

      if(uidActiveToken > 0) {
        axios.get(
          PortalOneWebConfig('portalOneApiUrl'),
          { 
            headers: { Authentication: localStorage.getItem("access_token") },
            params: {action: "refresh_token" }
          }
        )
        .then((response) => {

          //@ts-ignore
          const newToken = response.headers.get("Authentication") ?? null;

          if (newToken === null) {
            setUser("invalid");
            return;
          }

          const uidNewToken = parseJwt(newToken)?.uid;
     
          if(uidNewToken > 0) { // maybe check little more than if there can be a uid extracted :D
            localStorage.setItem("access_token", response.data);
            console.log("successfully reauthenticated", response.data);
            console.log("newToken: ", newToken);
            setUser(newToken);
          }
          else {
            console.log("no valid token", response.data);
            localStorage.removeItem("access_token");
            setUser("invalid");
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.removeItem("access_token");
          setUser("invalid");
        });
      }
    }
    else {
      localStorage.removeItem("access_token");
      setUser("invalid");
    }
  } catch (error) {
    console.error(error);
    localStorage.removeItem("access_token");
    setUser("invalid");
  };
  }, []);

  function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

  return user;
}