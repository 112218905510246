import { Navigate, Outlet } from "react-router-dom";
import useAuthentication from "../identityProvider/useAuthentication";

export default function ProtectedRoutes() {

  const user = useAuthentication();
  console.log("ProtectedRoutes", user);

  switch(user) {
    case "pending":
      return <div>Authenticating...</div>; // spinner
    case "invalid":
      return <Navigate to="/" />;
    default:
      return <Outlet />;
  };

}