//import { useState } from "react"
import Box from "./Box"
import "./Board.css";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
import { setBoard, setTitle, addBox } from "./BoardStoreSlice"
//import { setStateFromConfig } from "./BoxesStoreSlice"
//import axios from 'axios';
import './Boxes.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import { IBox, IBoxElement } from './BoxesInterfaces'
import PortalOneWebConfig from "../../PortalOneWebConfig";
import { useEffect, useState } from "react";
import Navigation from "../Navigation";

function Board() {
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const boardState = useSelector((state: {boardStoreSlice: any}) => state.boardStoreSlice);

    const [editTitleState, setEditTitleState] = useState(false);
    const [yearSelected, setYearSelected] = useState(2024);


    useEffect(() => {
      loadBoard();
    }, []); 


    const toggleEditTitleState = () => setEditTitleState(!editTitleState);

    const updateTitle = (title: string) => {
        //@ts-ignore
        dispatch(setTitle(title));
    };


    //const createBox = (boxIndex: number) => {
    const createBox = () => {
        //@ts-ignore
        //dispatch(addBox(boxIndex));
        dispatch(addBox("new box dummytitle"));
        console.log("createBox");
    }



    const saveBoard = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const boardId = urlParams.get('boardId');
    if (boardId) {
      axios.get(PortalOneWebConfig("portalOneApiUrl"), { 
        headers: { Authentication: localStorage.getItem("access_token") }, 
        params: { action: "setBoard", boardId: boardId, board: JSON.stringify(boardState) }
        })
        .then(res => {
          console.log(res);
          alert("Board saved");
        }).catch(err => {
          console.log(err);
        });
      }
    }



    const loadBoard = () => {
      
      const urlParams = new URLSearchParams(window.location.search);
      const boardId = urlParams.get('boardId');
      if (boardId) {
        axios.get(PortalOneWebConfig("portalOneApiUrl"), {
          headers: { Authentication: localStorage.getItem("access_token") },
          params: { action: "getBoard", boardId: boardId }
          })
          .then(res => {
            console.log(res);
            //vorher gegen regex oder typen prüfen -> something went wrong ist besser als abschmieren
            dispatch(setBoard(res.data))
          }).catch(err => {
            console.log(err);
          }
        );
      }
    }

//duplicate box.tsx
  const boxTotal = (frequency: string = '', boxId: number, year: number): number => {
    //boardState.boxes[boxId].elements.reduce((total: number, element: any) => console.log(new Date(element.date).getFullYear()));
    //@ts-ignore
    switch(frequency) {
      case 'm':
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'm' && new Date(element.date).getFullYear() === year) ? 
            (element.type === 'i') ? element.total : 
            element.total * -1 : 0), 0);
      case 'y':
        return boxTotal('m', boxId, year)*12 + boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'y' && new Date(element.date).getFullYear() === year) ?  (element.type === 'i') ? element.total : element.total * -1 : 0), 0);
      case 'o':
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.frequency === 'o' && new Date(element.date).getFullYear() === year) ?  (element.type === 'i') ? element.total : element.total * -1 : 0), 0);
      default:
        return boardState.boxes[boxId].elements.reduce((total: number, element: any) => 
          total + ((element.type === 'i') ? element.total : element.total * -1), 0);
    }
  }

    //(yearly + onetime) /12 + monthly
    const MonthlyAvg = () => {
      let sum = 0;
      boardState.boxes.forEach((box: any, index: number) => {
        sum += boxTotal('y', index, yearSelected) / 12; //already considers monthly payments
        sum += boxTotal('o', index, yearSelected) / 12;
      })
      return sum;
    }

    const YearlyAvg = () => {
      let sum = 0;
      boardState.boxes.forEach((box: any, index: number) => {
        sum += boxTotal('y', index, yearSelected); //already considers monthly payments
        sum += boxTotal('o', index, yearSelected);
      })
      return sum;
    }

    const AllOneTimers = () => {
      let sum = 0;
      boardState.boxes.forEach((box: any, index: number) => {
        sum += boxTotal('o', index, yearSelected);
      })
      return sum;
    }

    /*const Untill1First = () => {
      return 0;
    }

    const UntillEndOfYear = () => {
      return 0;
    }


    const untillDate = (date: Date) => {
      return 0;
    }*/

    const handleYearChange = (event: any) => {
      setYearSelected(parseInt(event.target.value));
    } 
    
    


    return (
      <>

        <Navigation title={boardState.title} showBackButton={true} backButtonRoute="/listOfBoards" showIcon={true} iconCallback={() => navigate("/user-profile")}/>
        <div style={{textAlign: 'center', paddingLeft: '12px', paddingRight: '12px'}}>
          {editTitleState && <input type="text" className="form-control" value={boardState.title} onChange={(e) => updateTitle(e.target.value)} /> }
          <div>
            <button className="finansp-btn-secondary" onClick={() => toggleEditTitleState()}>{!editTitleState ? "edit title" : "done"}</button>
            <button className="finansp-btn-dark" onClick={() => saveBoard()}><i className="fa fa-save" /> save Board</button>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <span className="year-picker">
                  <button className="" onClick={() => setYearSelected(yearSelected - 1)}>&lt;</button>
                  <input type="text" className="year-picker" value={yearSelected} disabled />
                  <button className="" onClick={() => setYearSelected(yearSelected + 1)}>&gt;</button>
                </span>
              </div>
              <table style={{width: '100%', textAlign: 'center'}}>
                <tbody>
                  <tr>
                    <td><p className="year-font-lg" style={{margin: '0'}}>Monthly Average</p></td>
                    <td><p className="year-font-lg" style={{margin: '0'}}>One Time Payments</p></td>
                    <td><p className="year-font-lg" style={{margin: '0'}}>Yearly Total</p></td>
                  </tr>
                  <tr>
                    <td><p>{MonthlyAvg().toFixed(2)} €</p></td>
                    <td><p>{AllOneTimers().toFixed(2)} €</p></td>
                    <td><p>{YearlyAvg().toFixed(2)} €</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        
        <hr />
        <div className="box-grid">
          {boardState.boxes.map((o: string, i: number) => {
            return (
                <Box key={"box_" + (i)} boxid={i} year={yearSelected}/>
            )
          })}

          <div className="">
            <div className="card">
              <div className="card-body finansp-btn-dark" style={{textAlign: 'center'}} onClick={() => createBox()}>
                <i className="fa fa-pen-to-square" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Board;